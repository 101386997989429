import "../assets/styles/TitleHome.scss";
import jakeProfilePhoto from "../assets/images/jakeProfilePhoto.png";

const TitleHome = () => {
    return (
        <section className="body-section">
            <div className="space"></div>
            <div className="title">
                <h1 className="heading" id="home">
                    Hi I'm{" "}
                    <span className="important">
                        Jake Hill
                        <hr />
                    </span>
                </h1>
                <p>3D Artist | Software Dev</p>
                <div className="buttons">
                    <a href="#contact">
                        <button className="button-large">contact me</button>
                    </a>
                </div>
            </div>
            <div className="image">
                <img
                    src={jakeProfilePhoto}
                    alt="Jake in a suit"
                    className="photo"
                />
            </div>
        </section>
    );
};

export default TitleHome;
