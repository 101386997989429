import { useRef } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
    const form = useRef();
    //   const PUBLIC_KEY = process.env.EMAILJS_PUB_KEY;
    const PUBLIC_KEY = "78jayx2iyXmuK9P6x";

    //   const SERVICE_ID = process.env.EMAILJS_ID;
    const SERVICE_ID = "service_d709csg";

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(SERVICE_ID, "template_vumehiy", form.current, PUBLIC_KEY)
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
        alert("Thankyou, your email has been sent.");
        window.location.reload(false);
    };

    return (
        <form className="contact-form" ref={form} onSubmit={sendEmail}>
            <div className="form-text">
                <input
                    name="user_name"
                    className="input text"
                    type="text"
                    placeholder="Name..."
                    required="required"
                ></input>
                <input
                    name="user_email"
                    className="input text"
                    type="email"
                    placeholder="Email..."
                    required="required"
                ></input>
            </div>
            <div className="form-text">
                <input
                    name="user_subject"
                    className="input subject"
                    type="text"
                    placeholder="Subject..."
                    required="required"
                ></input>
            </div>
            <div className="form-text">
                <textarea
                    name="user_message"
                    className="input message"
                    placeholder="Message..."
                    rows="6"
                    cols="50"
                    required="required"
                ></textarea>
            </div>
            <input
                className="button-large"
                id="send-message"
                type="submit"
                value="Send"
            ></input>
        </form>
    );
};

export default ContactForm;
