import "../assets/styles/TitleProduct.scss";

const TitleProduct = () => {
    return (
        <>
            <div className="testimonials">
                <div>
                    <h2 className="heading">Testimonials</h2>
                    <p>
                        <span>
                            “This Photoshop tool has been a time-saver for me.
                            <br />
                            It's simple to add links to masks with just a few
                            clicks.”
                        </span>
                    </p>
                    <p>- Ian (Senior 3D Team Leader)</p>
                </div>
            </div>
            {/* <div className="triangle-down"></div> */}
        </>
    );
};

export default TitleProduct;
