const HowMaskLink = [
    {
        title: "Why use MaskLink?",
        blurb: "This video gives you an idea of power of MaskLink to upate many masks in one click.",
        image: null,
        video: "https://www.youtube.com/embed/Y5P6YHCqId8?si=M67XDIgzsU0rr7ip",
        altText: "MaskLink preview",
    },
    {
        title: "How to use MaskLink",
        blurb: "This video will show you how you can use MaskLink to add, update and remove links. COMING SOON",
        image: null,
        video: null,
        altText: "MaskLink how to guide",
    },
];

export default HowMaskLink;
