import "./assets/styles/Pages.scss";
import MaskLink from "./pages/MaskLink";
import SmartBuilder from "./pages/SmartBuilder";
import Homepage from "./pages/Homepage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Homepage />,
    },
    {
        path: "/masklink",
        element: <MaskLink />,
    },
    {
        path: "/smartbuilder",
        element: <SmartBuilder />,
    },
]);
function App() {
    return <RouterProvider router={router} />;
}

export default App;
