import "../assets/styles/About.scss";
import jakeProfilePhoto from "../assets/images/jakeSecondaryPhoto.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCubes,
    faLaptopCode,
    faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

const About = () => {
    return (
        <div className="about">
            <h1 className="heading" id="about">
                About me
            </h1>
            <section className="body-section">
                <div className="skills">
                    <div className="icon-list">
                        <div className="icon">
                            <FontAwesomeIcon icon={faLaptopCode} />
                            <p>Development</p>
                        </div>
                        <div className="icon">
                            <FontAwesomeIcon icon={faCubes} />
                            <p>3D Vis</p>
                        </div>
                        <div className="icon">
                            <FontAwesomeIcon icon={faScrewdriverWrench} />
                            <p>Automation</p>
                        </div>
                        <hr />
                    </div>
                    <p className="paragraph">
                        Holding a Bachelor in Computer Science after 10 years in
                        the 3D visualisation industry, I have now combined my 3D
                        skills with my love for automation and development. I am
                        experienced in render farm scripting to improve
                        processing times, along with Photoshop plugin
                        development to optimise the rendering workflow. I bring
                        together 3D expertise and technical skills to improve
                        workflows and automate difficult tasks in the rendering
                        pipeline.
                    </p>
                </div>
                {/* <div className="image" id="about-image">
                    <img
                        src={jakeProfilePhoto}
                        alt="Jake in a suit"
                        className="photo"
                    />
                </div> */}
            </section>
        </div>
    );
};

export default About;
