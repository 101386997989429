const HowSmartBuilder = [
    {
        title: "1. Photoshop template file",
        blurb: "Create a template you want as a starting point for post production. This base file will be updated with links from your render.",
        image: require("../assets/images/smartbuilder/1_psd_template.jpg"),
        altText: "1_psd_template preview",
    },
    {
        title: "2. Smart Builder is added to Deadline",
        blurb: "Deadline will create an extra jobs on demand to build or update your Photoshop files every time a render finishes.",
        image: require("../assets/images/smartbuilder/2_Deadline.jpg"),
        altText: "2_Deadline preview",
    },
    {
        title: "3. Auto create/update Photoshop files",
        blurb: "Smart Builder will create Photoshop files based on your template and render images. Masks added with Mask Link will be updated as well.",
        image: require("../assets/images/smartbuilder/3_Create_PSD.jpg"),
        altText: "3_Create_PSD preview",
    },
    {
        title: "4. Option to save an output image",
        blurb: "Save an image output for collaboration with clients or other team members to easily view and comment.",
        image: require("../assets/images/smartbuilder/4_Export_jpgs.jpg"),
        altText: "4_Export_jpgs preview",
    },
    // {
    //     title: "5. Customised to suit your workflow",
    //     blurb: "Contact me to learn more!",
    //     image: null,
    //     altText: null,
    // },
];

export default HowSmartBuilder;
