import NavBar from "../features/nav-bar";
import TitleProduct from "../features/TitleProduct";
// import About from "../../components/About";
import HeroDisplay from "../features/HeroDisplay";
import Contact from "../features/Contact";
import HowItWorks from "../features/HowItWorks";
import HowSmartBuilder from "../components/HowSmartBuilderDetails";
// import "./MaskLink.scss";

function SmartBuilder() {
    // title details
    let icon = require("../assets/images/icons/SmartBuilder_192.png");
    let title = "Smart Builder";
    let subTitle = "Adobe Photoshop automation with AWS Thinkbox Deadline";
    let smallText = "Deadline/Photoshop connection";
    let actionButton = {
        text: "Contact Me",
        link: "#contact",
        newTab: false,
    };
    // Hero details
    let image = require("../assets/images/smartbuilder/PS-Deadline-Icons1.png");
    let imageGraphic = require("../assets/images/smartbuilder/PS-Deadline-Icons2.png");
    let imageStyle = "smartbuilder-image";
    let imageStyleGraphic = "graphic-image-SB";
    let featureList = [
        "Automate creating PS files",
        "Automate updating PS files",
        "Leverage your render farm",
        "Create a consistent workflow",
    ];
    return (
        <div className="App">
            <NavBar route={"/smartbuilder"} />
            <TitleProduct
                icon={icon}
                title={title}
                subTitle={subTitle}
                smallText={smallText}
                actionButton={actionButton}
                image={image}
                imageStyle={imageStyle}
            />
            <HeroDisplay
                title={"Deadline to Photoshop connection"}
                featureList={featureList}
                image={imageGraphic}
                imageStyle={imageStyleGraphic}
            />
            <HowItWorks howDetails={HowSmartBuilder} />
            {/* <About /> */}
            <Contact />
        </div>
    );
}

export default SmartBuilder;
