import "../assets/styles/HeroDisplay.scss";

const HeroDisplay = ({ title, featureList, image, imageStyle }) => {
    let tickIcon = require("../assets/images/icons/Tick.png");
    return (
        <>
            {/* <div className="triangle-down"></div> */}
            <div className="hero-display">
                <section className="body-section">
                    <div className="pannel-hero">
                        <div className={imageStyle}>
                            <h2 className="sub-heading">{title}</h2>

                            <img src={image} alt="Product screenshot" />
                        </div>
                    </div>

                    <div className="pannel-hero">
                        <div className="product-text">
                            <h2 className="sub-heading">
                                Speed up your 3D workflow
                            </h2>
                            {featureList.map((item, key) => (
                                <div key={key}>
                                    <img src={tickIcon} alt="Tick icon" />
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default HeroDisplay;
